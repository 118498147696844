.wrapper {
  @apply flex flex-col items-center justify-center font-bold;

  position: absolute;
  inset: 0;
  color: white;
  z-index: var(--z-index-100);
}

.blockScreen {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
}

.spinner,
.spinner:after {
  border-radius: 50%;
  width: 0.875rem;
  height: 0.875rem;
  box-sizing: content-box;
}

.spinner {
  @apply border-accent;
  position: relative;
  text-indent: -9999em;
  border-width: 0.25rem;
  border-style: solid;
  border-top-color: rgba(229, 231, 235, 1);
  border-right-color: rgba(229, 231, 235, 1);
  border-bottom-color: rgba(229, 231, 235, 1);
  transform: translateZ(0);
  animation: spinner 1.1s infinite linear;
  z-index: 1;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
