body {
  font-family: 'Roboto', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Epilogue', sans-serif;
  font-weight: 600;
}

h1 {
  @apply text-2xl;
}

h2 {
  @apply text-xl;
}
